<template>
  <div class="recharge-wrap">
    <div class="bread-wrap">
      <span class="bread-title">线路诊断工具</span>
    </div>

    <div class="contents">
      <div class="search">
        <!-- <span>资源ID：</span> -->
        <el-input
          class="search-input"
          v-model="currentIdOrIp"
          size="mini"
          placeholder="请输入资源ID或IP地址"
          style="width: 250px"
          @keyup.enter.native="handleSearch"
          clearable
        />
        <el-button
          class="filter-item"
          size="mini"
          type="primary"
          style="margin-left: 10px; width: 60px"
          @click="handleSearch"
        >
          查询
        </el-button>
      </div>

      <el-tabs
        v-model="activeName"
        style="margin-left: 20px; margin-right: 20px"
      >
        <el-tab-pane label="线路详情" name="linedetail">
          <div class="content" v-if="isShow">
            <div class="basicmessge">
              <div style="font-size: 18px">基本信息</div>
              <br />
              <div class="detail">
                <span class="dtcontent">名称</span
                ><span class="dttext">{{ currentRow.name }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">资源ID</span
                ><span class="dttext">{{ currentRow.name }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">规格</span
                ><span class="dttext">{{
                  productType(currentRow.productId)
                }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">加速区域</span
                ><span class="dttext">{{ ipRegion(currentRow.ipRegion) }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">业务组</span
                ><span class="dttext">{{ currentRow.groupName }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">IP地址</span
                ><span class="dttext">{{ currentRow.ip }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">状态</span
                ><span class="dttext">{{ lineStatus(currentRow.status) }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">创建时间</span
                ><span class="dttext">{{
                  dealTime(currentRow.createTime, "YYYY-MM-DD")
                }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">到期时间</span
                ><span class="dttext">{{
                  dealTime(currentRow.expireTime, "YYYY-MM-DD")
                }}</span>
              </div>
              <el-divider></el-divider>
              <div class="detail">
                <span class="dtcontent">自动续费</span
                ><span class="dttext">{{ currentAutoRenew }}</span>
              </div>
              <el-divider></el-divider>
            </div>
            <br />
            <div class="connectmessge">
              <div style="font-size: 18px">线路连接记录</div>
              <br />
              <com-table
                class="connectlist"
                :headercellstyle="headercellstyle"
                :columns="connectList"
                tableheight="260px"
                :data="connecttabledata"
              >
              </com-table>
              <div v-if="!isZircon">
                <br />
                <div style="font-size: 18px">网络监控图</div>
                <br />
                <el-select v-model="timeRange" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-button @click="drawChart(currentRow)"> 刷新 </el-button>
                <br /><br />
                <div ref="chart" style="width: 600px; height: 400px"></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="线路稳定性" name="linestability">
          <div class="content" v-if="isShow">
            <div class="stabilitymessage">
              <div style="font-size: 18px">延迟监控图</div>
              <br />
              <el-select v-model="pingTimeRange" placeholder="请选择">
                <el-option
                  v-for="item in pingoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-button @click="drawDelayChart(currentRow)"> 刷新 </el-button>
              <br /><br />
              <div ref="delayChart" style="width: 600px; height: 400px"></div>
            </div>
            <div v-if="!isZircon" class="pingmessage">
              <div style="font-size: 18px">下车点ping结果展示</div>

              <div class="search">
                <span>IP地址：</span>
                <el-input
                  class="search-input"
                  v-model="currentIp"
                  size="mini"
                  style="width: 130px"
                  :disabled="true"
                />
                <el-button
                  class="filter-item"
                  size="mini"
                  type="primary"
                  style="margin-left: 10px; width: 120px"
                  :disabled="pingbtn"
                  @click="getpingdetail"
                >
                  获取实时ping结果
                </el-button>
                <el-button
                  class="filter-item"
                  size="mini"
                  type="primary"
                  style="margin-left: 10px; width: 160px"
                  :disabled="pingbtn"
                  @click="gethighpingdetail"
                >
                  获取高精度实时ping结果
                </el-button>
              </div>
              <div class="detail">
                <!-- <pre>{{pingData}}</pre> -->
                <v-col
                  class="px-0 pb-1"
                  style="white-space: pre-wrap; font-size: 12px"
                >
                  <h3>{{ pingData }}</h3>
                </v-col>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import formatdate from "@/utils/format";
import { getToken } from "@/utils/auth";
import {
  IpRegionMap,
  ProductTypeMap,
  LineStatusMap,
  isZirconLine,
} from "@/utils/constants";
export default {
  computed: {
    isZircon() {
      return isZirconLine(this.currentRow.productId);
    },
  },
  data() {
    return {
      activeName: "linedetail",
      myChart: null,
      myDelayChart: null,
      timeRange: "3600",
      pingTimeRange: "3600",
      options: [
        {
          value: "3600",
          label: "1小时",
        },
        {
          value: "21600",
          label: "6小时",
        },
        {
          value: "86400",
          label: "1天",
        },
        {
          value: "259200",
          label: "3天",
        },
      ],
      pingoptions: [
        {
          value: "3600",
          label: "1小时",
        },
        {
          value: "21600",
          label: "6小时",
        },
        {
          value: "86400",
          label: "1天",
        },
        {
          value: "259200",
          label: "3天",
        },
      ],
      drawer: false,
      isSearch: false,
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      showdelete: false,
      metricslist: [],
      metricslistx: [],
      metricslisty: [],
      metricsdelaylist: [],
      metricsdelaylistx: [],
      metricsdelaylisty: [],
      currentIdOrIp: "",
      currentIp: "",
      currentRow: {},
      currentAutoRenew: "",
      optionss: {
        title: {
          text: "出向峰值带宽（Mbps）",
          subtext: "",
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.metricslistx,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },

        series: [
          {
            symbol: "circle", //是否显示实心的折线圆点
            symbolSize: 0, //折线圆点的大小
            type: "line",
            data: this.metricslisty,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#ffffff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.5,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {},
          },
        ],
        brush: {
          yAxisIndex: "all",
          brushType: "lineX",
          brushMode: "single",
        },
        toolbox: {
          show: false,
        },
      },
      delayoptionss: {
        title: {
          text: "（ms）",
          subtext: "",
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.metricsdelaylistx,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          max: (value) => (value.max > 9999 ? 2000 : null),
        },

        series: [
          {
            symbol: "circle", //是否显示实心的折线圆点
            symbolSize: 0, //折线圆点的大小
            type: "line",
            data: this.metricsdelaylisty,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#ffffff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.5,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {},
          },
        ],
        brush: {
          yAxisIndex: "all",
          brushType: "lineX",
          brushMode: "single",
        },
        toolbox: {
          show: false,
        },
      },
      connectList: [
        {
          prop: "connectTime",
          label: "连接时间",
          width: "120",
          align: "center",
          headerAlign: "center",

          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD HH:MM:SS");
          },
        },
        {
          prop: "connectSource",
          label: "连接设备",
          width: "300",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      connecttabledata: [],
      pingData: "",
      isShow: false,
      linelistdata: [],
      pingbtn: false,
    };
  },
  activated() {
    if (this.$route.params.row) {
      this.currentRow = this.$route.params.row;
      this.currentIdOrIp = this.currentRow.id;
      this.currentIp = this.currentRow.ip;
      this.showDetail();
    }
    this.getuserinfo();
  },
  components: {
    ComTable,
  },
  watch: {
    timeRange: {
      handler: function (val) {
        this.drawChart();
      },
    },
    pingTimeRange: {
      handler: function (val) {
        this.drawDelayChart();
      },
    },
  },
  methods: {
    showDetail() {
      if (this.currentRow.id != null && this.currentRow.id.length > 0) {
        this.currentIp = this.currentRow.ip;
        this.pingData = "";
        this.isShow = true;
        this.drawer = true;
        if (this.currentRow.autoRenew == 0) {
          this.currentAutoRenew = "未开启";
        } else if (this.currentRow.autoRenew == 7) {
          this.currentAutoRenew = "已开启：按7天";
        } else if (this.currentRow.autoRenew == 30) {
          this.currentAutoRenew = "已开启：按月";
        } else if (this.currentRow.autoRenew == 90) {
          this.currentAutoRenew = "已开启：按季度";
        } else if (this.currentRow.autoRenew == 180) {
          this.currentAutoRenew = "已开启：按半年";
        } else if (this.currentRow.autoRenew == 360) {
          this.currentAutoRenew = "已开启：按年";
        }
        this.getconnectdetail();
        this.myDelayChart = null;
        this.myChart = null;
        if (!this.myChart) {
          this.$nextTick(() => {
            this.myChart = this.$echarts.init(this.$refs.chart);
            this.myChart.on("brushEnd", this.brushEnd);
            this.drawChart();
          });
        }

        if (!this.myDelayChart) {
          this.$nextTick(() => {
            this.myDelayChart = this.$echarts.init(this.$refs.delayChart);
            this.myDelayChart.on("brushEnd", this.brushDelayEnd);
            this.drawDelayChart();
          });
        }
      } else {
        this.$message.error("请输入本账户下已购资源的资源ID或IP地址~");
        this.isShow = false;
      }
    },

    brushEnd(params) {
      console.log(params);
      let range = params.areas?.[0]?.coordRange;
      if (range && range.length == 2) {
        if (!this.metricslist?.[range[0]] || !this.metricslist?.[range[1]]) {
          return;
        }
        let beginTime = this.metricslist?.[range[0]].timestamp;
        let endTime = this.metricslist?.[range[1]].timestamp;
        this.drawChart(beginTime, endTime);
      }
    },
    brushDelayEnd(params) {
      console.log(params);
      let range = params.areas?.[0]?.coordRange;
      if (range && range.length == 2) {
        if (
          !this.metricsdelaylist?.[range[0]] ||
          !this.metricsdelaylist?.[range[1]]
        ) {
          return;
        }
        let beginTime = this.metricsdelaylist?.[range[0]].timestamp;
        let endTime = this.metricsdelaylist?.[range[1]].timestamp;
        this.drawDelayChart(beginTime, endTime);
      }
    },
    drawChart(beginTime = 0, endTime = 0) {
      let data = {
        id: this.currentRow.id,
      };
      if (beginTime != 0 && endTime != 0) {
        data.beginTime = beginTime;
        data.endTime = endTime;
      } else {
        data.timeRange = this.timeRange;
      }
      this.myChart.showLoading();
      this.$store.dispatch("line/setlineipmetrics", { ...data }).then((res) => {
        this.myChart.hideLoading();
        this.metricslisty = [];
        this.metricslistx = [];
        if (res.retCode == 0) {
          this.metricslist = res.metrics;
          this.metricslist.forEach((item1, index, arr) => {
            this.metricslisty.push(
              (res.metrics[index].value / 1000000).toFixed(5)
            );
            this.metricslistx.push(
              formatdate(res.metrics[index].timestamp, "MM-DD HH:MM:SS")
            );
          });
          this.optionss.xAxis.data = this.metricslistx;
          this.optionss.series[0].data = this.metricslisty;

          this.myChart.setOption(this.optionss);

          this.myChart.dispatchAction({
            type: "brush",
            areas: [],
          });
          this.myChart.dispatchAction({
            type: "takeGlobalCursor",
            key: "brush",
            brushOption: {
              brushType: "lineX",
              brushMode: "single",
            },
          });
        }
      });
    },
    drawDelayChart(beginTime = 0, endTime = 0) {
      let data = {
        id: this.currentRow.id,
      };
      if (beginTime != 0 && endTime != 0) {
        data.beginTime = beginTime;
        data.endTime = endTime;
      } else {
        data.timeRange = this.pingTimeRange;
      }
      this.myDelayChart.showLoading();
      this.$store
        .dispatch("line/setlinepingmetrics", { ...data })
        .then((res) => {
          this.myDelayChart.hideLoading();
          this.metricsdelaylisty = [];
          this.metricsdelaylistx = [];
          if (res.retCode == 0) {
            this.metricsdelaylist = res.networkOut;
            this.metricsdelaylist.forEach((item1, index, arr) => {
              if (res.networkOut[index].value - 0 > 9999) {
                this.metricsdelaylisty.push("0");
              } else {
                this.metricsdelaylisty.push(res.networkOut[index].value);
              }

              this.metricsdelaylistx.push(
                formatdate(res.networkOut[index].timestamp, "MM-DD HH:MM:SS")
              );
            });
            this.delayoptionss.xAxis.data = this.metricsdelaylistx;
            this.delayoptionss.series[0].data = this.metricsdelaylisty;

            this.myDelayChart.setOption(this.delayoptionss);

            this.myDelayChart.dispatchAction({
              type: "brush",
              areas: [],
            });
            this.myDelayChart.dispatchAction({
              type: "takeGlobalCursor",
              key: "brush",
              brushOption: {
                brushType: "lineX",
                brushMode: "single",
              },
            });
          }
        });
    },
    getconnectdetail() {
      let data = {
        id: this.currentRow.id,
      };
      this.connecttabledata = [];
      this.$store
        .dispatch("line/getconnetctdetails", { ...data })
        .then((res) => {
          console.log("get connectdetail response: " + JSON.stringify(res));
          if (res.retCode == 0) {
            if (res.connectData.length > 5) {
              for (let i = 0; i < 5; i++) {
                this.connecttabledata.push(res.connectData[i]);
              }
            } else {
              this.connecttabledata = res.connectData;
            }
          }
        });
    },

    getpingdetail() {
      this.pingbtn = true;
      var pingtime = 30;
      var timer = setInterval(() => {
        pingtime--;
        if (pingtime == 0) {
          this.pingbtn = false;
          clearInterval(timer);
        }
      }, 1000);
      let data = {
        id: this.currentRow.id,
      };
      data.interval = 1;
      this.$store.dispatch("line/getpingdetails", { ...data }).then((res) => {
        console.log("get pingdetail response: " + JSON.stringify(res));
        if (res.retCode == 0) {
          this.pingData = res.pingData;
        } else {
          this.pingData = res.message;
        }
      });
    },
    gethighpingdetail() {
      this.pingbtn = true;
      var pingtime = 30;
      var timer = setInterval(() => {
        pingtime--;
        if (pingtime == 0) {
          this.pingbtn = false;
          clearInterval(timer);
        }
      }, 1000);
      let data = {
        id: this.currentRow.id,
      };
      data.interval = 0.1;
      this.$store.dispatch("line/getpingdetails", { ...data }).then((res) => {
        console.log("get pingdetail response: " + JSON.stringify(res));
        if (res.retCode == 0) {
          this.pingData = res.pingData;
        } else {
          this.pingData = res.message;
        }
      });
    },

    handleSearch() {
      if (this.currentIdOrIp != null && this.currentIdOrIp.length > 0) {
        this.getlinelist();
      } else {
        this.$message.error("请输入需要查询的资源ID或IP地址~");
      }
    },

    getlinelist() {
      var hasToken = getToken();
      this.currentRow = {};
      this.$store
        .dispatch("line/getlinelist", { Authorization: hasToken })
        .then((res) => {
          // console.log("get linelist response: " + JSON.stringify(res));
          if (res.lineInfos !== null && res.lineInfos.length > 0) {
            this.linelistdata = res.lineInfos;
            this.linelistdata.forEach((item1, index, arr) => {
              if (
                item1.id == this.currentIdOrIp ||
                item1.ip == this.currentIdOrIp
              ) {
                this.currentRow = item1;
              }
            });
            this.showDetail();
          }
        });
    },

    dealTime(time) {
      return formatdate(time, "YYYY-MM-DD");
    },
    lineStatus(status) {
      return LineStatusMap[status];
    },
    productType(productId) {
      return ProductTypeMap[productId];
    },
    ipRegion(ipRegion) {
      return IpRegionMap[ipRegion];
    },

    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
